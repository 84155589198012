import React, {ChangeEvent, useState} from 'react';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Switch from "@mui/material/Switch";
import Checkbox from '@mui/material/Checkbox';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";

import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import {logAction, readRealtime, setRealtime, uploadFileToStorage} from "../hooks/firebase";
import {Product, ProductOption} from "../models/Product";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/PlusOne';
import UploadIcon from "@mui/icons-material/Upload";
import {v4 as uuidv4} from "uuid";
import {Area, Areas} from "../models/Pickup";
import {useRead} from "../hooks/realtime";


export default function (props: { onClose: (update: boolean) => void }) {
    const {onClose} = props;
    const [tab, setTab] = useState<string>('product');
    const [product, setProduct] = useState<Product>({
        id: 'Seoul_Regular_',
        area: 'Seoul',
        name: '',
        category: 'Regular',
        status: 'STOP',
        availableBefore: 8,
        availableDays: [false, false, false, false, false, false, false, false,],
        departureTime: '0900',
        possibles: [],
        option: [],
        pickup: [],
        memo: ''
    });

    const {data: areas} = useRead<Areas>(`pickup`);
    const area = areas?.[product?.area?.toLowerCase() ?? ''];

    const update = (prop: string, value: any) => {
        setProduct((p) => {
            if (!p) return p;
            return {...p, [prop]: value};
        })
    }


    const handleSave = () => {
        const filteredPossibles = (product?.possibles ?? []).filter(v => !!v);
        const adjustedOptions = (((product?.option?.length ?? 0) > 0 && !product?.option?.find((o) => o.option.toLowerCase() === 'ignore'))
                ? [...(product?.option ?? []), {
                    option: 'Ignore',
                    possibles: []
                }]
                : (product?.option ?? [])
        )
            .sort((a, b) => a.option.toLowerCase() === 'ignore' ? 1 : 0);

        if(!product.name || !product.category || !product.area) {
            alert('이름을 작성하세요')
            return;
        }
        const updates = {
            ...product,
            id: `${product.area}_${product.category.replace(/[\s/]/gi, '')}_${product.name.replace(/[\s/]/gi, '')}`,
            possibles: filteredPossibles,
            option: adjustedOptions
        }

        const ref = `product/${updates.id}`;
        readRealtime(ref)
            .then((v) => {
                if (v) {
                    return alert('Already exists. Please check area, category and name');
                }
                return setRealtime(ref, updates)
            })
            .then((v) => {
                logAction('PRODUCT', 'CREATE PRODUCT', updates.id, `Create new product: ${updates.id}(${updates.area}, ${updates.name})`, updates);
                onClose(true);
            })
            .catch((e) => alert('Fail on save'));

    }
    const handleChangeBuilder = (prop: string) => (_: any, value: any) => {
        update(prop, value);
    }
    const handleTargetChangeBuilder = (prop: string, middleware: (value: any) => any = f => f) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = middleware(e.target.value);
        update(prop, value);
    }


    return (
        <Modal
            open
            onClose={onClose}
        >
            <Box
                sx={(theme) => ({
                    width: '80vw',
                    maxHeight: '80vh',
                    position: 'relative' as 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >


                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 125%)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 1,
                    px: 2
                }}>
                    <Switch checked={product?.status === 'ON'}
                            onChange={(e, value) => handleChangeBuilder('status')(e, value ? 'ON' : 'STOP')}/>
                    <IconButton onClick={handleSave}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => onClose(true)}>
                        <CloseIcon/>
                    </IconButton>

                </Box>

                <Paper
                    sx={(theme) => ({
                        padding: '32px 24px',
                    })}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    maxHeight: '75vh',
                                    overflowY: 'auto',
                                }}
                            >


                                <TabContext value={tab}>
                                    <Box>
                                        <TabList onChange={(e, tabValue) => {
                                            setTab(tabValue)
                                        }}>
                                            <Tab
                                                label={'Product'}
                                                value={'product'}
                                            />
                                            <Tab
                                                label={'Chat'}
                                                value={'chat'}
                                            />
                                        </TabList>
                                    </Box>

                                    <Box
                                        sx={(theme) => ({
                                            borderRadius: 3,
                                            backgroundColor: theme.palette.background.default,
                                            p: 4
                                        })}
                                    >
                                        <TabPanel value={'product'}>
                                            <Grid
                                                container
                                                spacing={4}
                                                component={'form'}
                                            >
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        Area
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                >
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            name="position"
                                                            value={product?.area ?? ''}
                                                            onChange={handleChangeBuilder('area')}
                                                        >
                                                            <FormControlLabel
                                                                value="Seoul"
                                                                control={<Radio/>}
                                                                label="Seoul"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="Busan"
                                                                control={<Radio/>}
                                                                label="Busan"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="Tokyo"
                                                                control={<Radio/>}
                                                                label="Tokyo"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="Osaka"
                                                                control={<Radio/>}
                                                                label="Osaka"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>

                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                >
                                                    <Divider/>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        Info
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={5}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label={'Name'}
                                                        value={product?.name ?? ''}
                                                        onChange={handleTargetChangeBuilder('name')}
                                                        InputLabelProps={{shrink: true}}
                                                        sx={{backgroundColor: 'white'}}
                                                    />

                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={5}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label={'Category'}
                                                        value={product?.category ?? ''}
                                                        onChange={handleTargetChangeBuilder('category')}
                                                        InputLabelProps={{shrink: true}}
                                                        sx={{backgroundColor: 'white'}}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        Conditions
                                                    </Typography>
                                                </Grid>


                                                <Grid
                                                    item
                                                    xs={4}
                                                >
                                                    <ToggleButtonGroup
                                                        fullWidth
                                                        style={{
                                                            backgroundColor: 'white'
                                                        }}
                                                        value={product?.availableDays.map((on, idx) => on ? idx : false) ?? []}
                                                        onChange={
                                                            (e, value) => {
                                                                const values = product?.availableDays.map((on, idx) => (value as number[]).includes(idx));
                                                                handleChangeBuilder('availableDays')(e, values);
                                                            }
                                                        }
                                                    >
                                                        <ToggleButton value={0} color={'primary'} size={'large'}>
                                                            월
                                                        </ToggleButton>
                                                        <ToggleButton value={1} color={'primary'} size={'large'}>
                                                            화
                                                        </ToggleButton>
                                                        <ToggleButton value={2} color={'primary'} size={'large'}>
                                                            수
                                                        </ToggleButton>
                                                        <ToggleButton value={3} color={'primary'} size={'large'}>
                                                            목
                                                        </ToggleButton>
                                                        <ToggleButton value={4} color={'primary'} size={'large'}>
                                                            금
                                                        </ToggleButton>
                                                        <ToggleButton value={5} color={'primary'} size={'large'}>
                                                            토
                                                        </ToggleButton>
                                                        <ToggleButton value={6} color={'primary'} size={'large'}>
                                                            일
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label={'Departure Time'}
                                                        value={product?.departureTime ?? '0900'}
                                                        onChange={handleTargetChangeBuilder('departureTime')}
                                                        InputLabelProps={{shrink: true}}
                                                        sx={{backgroundColor: 'white'}}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label={'Winter Departure Time'}
                                                        value={product?.departureTimeWinter ?? '0900'}
                                                        onChange={handleTargetChangeBuilder('departureTimeWinter')}
                                                        InputLabelProps={{shrink: true}}
                                                        sx={{backgroundColor: 'white'}}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label={'Time limit'}
                                                        value={product?.availableBefore ?? ''}
                                                        onChange={handleTargetChangeBuilder('availableBefore')}
                                                        InputLabelProps={{shrink: true}}
                                                        sx={{backgroundColor: 'white'}}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                >
                                                    <Divider/>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        Memo
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        minRows={5}
                                                        sx={{
                                                            backgroundColor: 'white'
                                                        }}
                                                        value={product?.memo}
                                                        onChange={handleTargetChangeBuilder('memo')}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                >
                                                    <Divider/>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        Possibles
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                >
                                                    <Stack
                                                        gap={1}
                                                    >
                                                        {
                                                            (product?.possibles ?? []).map((p, i, array) => (
                                                                <TextField
                                                                    key={'possibles' + i}
                                                                    fullWidth
                                                                    style={{
                                                                        backgroundColor: 'white'
                                                                    }}
                                                                    value={p}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        const newArray = [...array]
                                                                        newArray[i] = value;
                                                                        handleChangeBuilder('possibles')(e, newArray);
                                                                    }}
                                                                />
                                                            ))
                                                        }
                                                        <Button
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: 'white',
                                                            }}
                                                            variant={'outlined'}
                                                            color={'primary'}
                                                            onClick={(e) => {
                                                                const newArray = [...(product?.possibles ?? []), ''];
                                                                handleChangeBuilder('possibles')(e, newArray);
                                                            }}
                                                        >
                                                            <PlusIcon/>
                                                        </Button>
                                                    </Stack>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                >
                                                    <Divider/>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={2}
                                                >

                                                    <Typography fontWeight={'bold'}>
                                                        Options
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                    >
                                                        {
                                                            (product?.option ?? []).map((option, idx, options) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        key={'option' + idx}
                                                                        xs={12}
                                                                        sm={6}
                                                                    >
                                                                        <Card
                                                                        >
                                                                            <CardHeader
                                                                                disableTypography
                                                                                action={
                                                                                    <IconButton
                                                                                        onClick={(e) => {
                                                                                            const newOptions = [...options]
                                                                                            newOptions.splice(idx, 1);
                                                                                            handleChangeBuilder('option')(e, newOptions)
                                                                                        }}
                                                                                    >
                                                                                        <CloseIcon/>
                                                                                    </IconButton>
                                                                                }
                                                                                title={
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        variant={'standard'}
                                                                                        value={option.option}
                                                                                        onChange={(e) => {
                                                                                            const value = e.target.value;
                                                                                            const newOption = {
                                                                                                ...option,
                                                                                                option: value
                                                                                            }
                                                                                            const newOptions = [...options]
                                                                                            newOptions[idx] = newOption;
                                                                                            handleChangeBuilder('option')(e, newOptions)
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />

                                                                            <CardContent>

                                                                                <Stack
                                                                                    gap={1}
                                                                                >
                                                                                    {
                                                                                        (option.possibles ?? []).map((p, i, array) => (
                                                                                            <TextField
                                                                                                key={'possibles' + i}
                                                                                                fullWidth
                                                                                                style={{
                                                                                                    backgroundColor: 'white'
                                                                                                }}
                                                                                                value={p}
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const newArray = [...array]
                                                                                                    newArray[i] = value;
                                                                                                    const newOption = {
                                                                                                        ...option,
                                                                                                        possibles: newArray
                                                                                                    }
                                                                                                    const newOptions = [...options]
                                                                                                    newOptions[idx] = newOption;
                                                                                                    handleChangeBuilder('option')(e, newOptions);
                                                                                                }}
                                                                                            />
                                                                                        ))
                                                                                    }
                                                                                    <Button
                                                                                        fullWidth
                                                                                        style={{
                                                                                            backgroundColor: 'white',
                                                                                        }}
                                                                                        variant={'outlined'}
                                                                                        color={'primary'}
                                                                                        onClick={(e) => {
                                                                                            const newArray = [...(option.possibles ?? []), ''];
                                                                                            const newOption = {
                                                                                                ...option,
                                                                                                possibles: newArray
                                                                                            }
                                                                                            const newOptions = [...options]
                                                                                            newOptions[idx] = newOption;
                                                                                            handleChangeBuilder('option')(e, newOptions);
                                                                                        }}
                                                                                    >
                                                                                        <PlusIcon/>
                                                                                    </Button>
                                                                                </Stack>
                                                                            </CardContent>

                                                                        </Card>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                        <Grid
                                                            item
                                                            xs={12}
                                                        >

                                                            <Button
                                                                fullWidth
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                }}
                                                                variant={'outlined'}
                                                                color={'primary'}
                                                                onClick={(e) => {
                                                                    const newArray = [...(product?.option ?? []), {
                                                                        option: '',
                                                                        possibles: []
                                                                    }];
                                                                    handleChangeBuilder('option')(e, newArray);
                                                                }}
                                                            >
                                                                <PlusIcon/>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={'chat'}>
                                            <ChatInner chat={product?.chat} name={product?.name} option={product?.option} area={area} onChange={handleChangeBuilder('chat')}/>
                                        </TabPanel>
                                    </Box>
                                </TabContext>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}


function ChatInner(props: { chat: { [propName: string]: any } | undefined, name: string | undefined, option: ProductOption[] | undefined, area: Area | undefined, onChange: (_: any, updated: { [propName: string]: any }) => void }) {
    let {chat, option, area, name, onChange} = props;
    const [uploading, setUploading] = useState<boolean>(false);

    if (!chat) {
        chat = {
            name: {en: '', cn: ''},
            message: '',
            option: {},
            pickup: {}
        }
    }
    if (!chat.option) {
        chat.option = {};
    }

    if (!chat.pickup) {
        chat.pickup = {};
    }


    const options = option
        ? option
            .filter((o) => o.option.toLowerCase() !== 'ignore')
            .map((op) => {
                const exist = Object.values(chat?.option ?? {}).find((cOp: any) => cOp.option === op.option);
                if (exist) return exist;
                return ({option: op.option, en: '', cn: ''})
            })
        : []
    const pickups = area
        ? Object.values(area)
            .map((pk) => {
                const exist = Object.values(chat?.pickup ?? {}).find((cPk: any) => cPk.place === pk.place);
                if (exist) return exist;
                return ({place: pk.place, en: '', cn: '', latLang: [], cnImage: '', enImage: '',})
            })
        : []

    const handleChangeValueBuilder = (propName: string) => (_: any, v: any) => {
        const propNames = propName.split(/[./]/);  //['title', 'en'];
        const updateObject = {...chat};
        const lastPropName = propNames.pop() as string;
        let cursor = updateObject;
        for (let pn of propNames) {
            cursor = cursor[pn] = {...cursor[pn]};

        }
        cursor[lastPropName] = v;
        onChange(_, updateObject);
    }

    const handleChangeEventTargetBuilder = (propName: string, middleware: (value: any) => any = f => f) => {
        const handleChangeValue = handleChangeValueBuilder(propName);
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = middleware(e.target.value ?? '');
            handleChangeValue(e, value);
        }
    }


    const changeFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files instanceof FileList && e.target.files.length > 0) {
            const file = e.target.files.item(e.target.files.length - 1);
            if (!file) return alert('참조된 파일이 없습니다.');
            if (!file.type.includes('ima')) alert('이미지 파일이 아닙니다.');
            setUploading(true);
            uploadFileToStorage(`public/${uuidv4()}`, file)
                .then((url) => {
                    handleChangeValueBuilder(`image`)(e, url);
                })
                .finally(() => {
                    setUploading(false);
                })
        }
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={'h5'}>
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography fontWeight={'bold'}>
                    Name
                </Typography>
            </Grid>

            <Grid item xs={10}>
                <TextField
                    fullWidth
                    label={'English'}
                    value={chat?.name?.en ?? ''}
                    onChange={handleChangeEventTargetBuilder('name.en')}
                />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    label={'Chinese'}
                    value={chat?.name?.cn ?? ''}
                    onChange={handleChangeEventTargetBuilder('name.cn')}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={2}>
                <Typography fontWeight={'bold'}>
                    Options
                </Typography>
            </Grid>
            <Grid item xs={10}>
                {
                    options.length > 0
                        ?
                        (options as { option: string, en: string, cn: string }[]).map((o, idx) => (
                            <InnerChatOption
                                option={o}
                                onChange={handleChangeValueBuilder(`option.${o.option.replace(/[.\/\\]/gi, '')}`)}
                            />
                        ))
                        : <Typography fontWeight={'bold'}>NONE</Typography>
                }
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={2}>
                <Typography fontWeight={'bold'}>
                    Message
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <TextField
                    multiline
                    fullWidth
                    value={chat?.message ?? ''}
                    onChange={handleChangeEventTargetBuilder(`message`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={2}>
                <Typography fontWeight={'bold'}>
                    Image
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'stretch',
                        mb: 2,
                        gap: 1,
                    }}
                >
                    <TextField
                        sx={{flex: 1}}
                        fullWidth
                        value={chat?.image ?? ''}
                        onChange={handleChangeEventTargetBuilder(`image`)}
                    />
                    <Button
                        component={'label'}
                        variant={'contained'}
                        disabled={uploading}
                    >
                        <UploadIcon/>
                        <input
                            type="file"
                            id="file"
                            style={{display: "none"}}
                            accept={"image/*"}
                            onChange={changeFile}
                        />
                    </Button>
                </Box>
                {
                    uploading
                        ?
                        <Box
                            sx={{
                                height: '20vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress/>
                        </Box>
                        :
                        chat?.image
                            ? (
                                <img
                                    style={{maxHeight:'50vh'}}
                                    src={chat?.image ?? ''}
                                />
                            ) : null
                }
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={2}>
                <Typography fontWeight={'bold'}>
                    Pickups
                </Typography>
            </Grid>
            <Grid item xs={10}>
                {
                    pickups.length > 0
                        ?
                        (pickups as {
                            place: string,
                            en: string,
                            cn: string,
                            latLang: string[],
                            cnImage: string,
                            enImage: string,
                        }[]).map((p, idx) => (
                            <InnerChatPickup
                                pickup={p}
                                onChange={handleChangeValueBuilder(`pickup.${p.place.replace(/[.\/\\]/gi, '')}`)}
                            />
                        ))
                        : <Typography fontWeight={'bold'}>NONE</Typography>
                }
            </Grid>

        </Grid>
    )
}

function InnerChatOption(props: { option: { option: string, cn: string, en: string }, onChange: (e: any, option: { option: string, cn: string, en: string }) => void }) {

    const {option, onChange} = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>
                    {option.option}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={'English'}
                    value={option.en ?? ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        onChange(e, {...option, en: value});
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={'Chinese'}
                    value={option.cn ?? ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        onChange(e, {...option, cn: value});
                    }}
                />
            </Grid>
            <Grid item xs={12}/>
        </Grid>
    )
}

function InnerChatPickup(props: {
    pickup: {
        place: string,
        en: string,
        cn: string,
        latLang: string[],
        cnImage: string,
        enImage: string,
    },
    onChange: (e: any, pickup: {
        place: string,
        en: string,
        cn: string,
        latLang: string[],
        cnImage: string,
        enImage: string,
    }) => void
}) {
    const [uploading, setUploading] = useState<boolean>(false);
    const {pickup, onChange} = props;
    const changeFileBuilder = (propName: 'enImage' | 'cnImage') => (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files instanceof FileList && e.target.files.length > 0) {
            setUploading(true);
            const file = e.target.files.item(e.target.files.length - 1);
            if (!file) return alert('참조된 파일이 없습니다.');
            if (!file.type.includes('ima')) alert('이미지 파일이 아닙니다.');

            uploadFileToStorage(`public/${uuidv4()}`, file)
                .then((url) => {
                    onChange(e, {...pickup, [propName]: url});
                })
                .catch(e => {
                    alert('파일 업로드에 실패했습니다.')
                })
                .finally(() => {
                    setUploading(false);
                })
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>
                    {pickup.place}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={'English'}
                    value={pickup.en ?? ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        onChange(e, {...pickup, en: value});
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={'Chinese'}
                    value={pickup.cn ?? ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        onChange(e, {...pickup, cn: value});
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'stretch',
                        mb: 1,
                        gap: 1,
                    }}
                >
                    <TextField
                        sx={{flex: 1}}
                        fullWidth
                        value={pickup?.enImage ?? ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            onChange(e, {...pickup, enImage: value});
                        }}
                    />
                    <Button
                        component={'label'}
                        variant={'contained'}
                        disabled={uploading}
                    >
                        <UploadIcon/>
                        <input
                            type="file"
                            id="file"
                            style={{display: "none"}}
                            accept={"image/*"}
                            onChange={changeFileBuilder('enImage')}
                        />
                    </Button>
                </Box>
                {
                    uploading
                        ? (
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh'}}>
                                <CircularProgress/>
                            </Box>
                        )
                        : (
                            <img
                                src={pickup.enImage}
                                width={'100%'}
                            />
                        )
                }
            </Grid>
            <Grid item xs={6}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'stretch',
                        mb: 1,
                        gap: 1,
                    }}
                >
                    <TextField
                        sx={{flex: 1}}
                        fullWidth
                        value={pickup?.cnImage ?? ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            onChange(e, {...pickup, cnImage: value});
                        }}
                    />
                    <Button
                        component={'label'}
                        variant={'contained'}
                        disabled={uploading}
                    >
                        <UploadIcon/>
                        <input
                            type="file"
                            id="file"
                            style={{display: "none"}}
                            accept={"image/*"}
                            onChange={changeFileBuilder('cnImage')}
                        />
                    </Button>
                </Box>
                {
                    uploading
                        ? (
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh'}}>
                                <CircularProgress/>
                            </Box>
                        )
                        : (
                            <img
                                src={pickup.cnImage}
                                width={'100%'}
                            />
                        )
                }
            </Grid>
            <Grid item xs={12}/>
        </Grid>
    )
}
