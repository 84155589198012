import React, { useState } from 'react'

import Box from '@mui/material/Box'

import {
  DataGridPro,
  GridToolbar,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridColDef, GridActionsCellItem,
} from '@mui/x-data-grid-pro'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import UserModal from '../../partials/UserModal'
import { useAuth } from '../../hooks/auth'
import {
  useListen,
  useListenPropValueStartAt,
  useRead,
  useReadPropValueStartAt,
} from '../../hooks/realtime'
import {
  callFunction,
  removeRealtime,
  updateRealtime,
} from '../../hooks/firebase'
import { User } from '../../models/User'
import Icon from '@mui/material/Icon'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOffOutlined'
import NoShowIcon from '@mui/icons-material/NoAccounts'
import NoShowOutlinedIcon from '@mui/icons-material/NoAccountsOutlined'
import TourIcon from '@mui/icons-material/Tour'
import TourOutlinedIcon from '@mui/icons-material/TourOutlined'
import DriverIcon from '@mui/icons-material/DriveEta'
import DriverOutlinedIcon from '@mui/icons-material/DriveEtaOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutline'
import AgentIcon from '@mui/icons-material/SupportAgent'
import AgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'
import { GridEventListener } from '@mui/x-data-grid/models/events'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { Operation } from '../../models/Operation'
import { downloadObjectAsJson } from '../../utils/utils'
import { Product } from '../../models/Product'
import Typography from '@mui/material/Typography'
import ProductModal from '../../partials/ProductModal'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import CreateProductModal from '../../partials/CreateProductModal'

export default function () {
  const [tab, setTab] = useState<number>(1)
  const { user, auth } = useAuth()
  const [productId, setProductId] = useState<string | null>(null)
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '')
  const [newProduct, setNewProduct] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)
  const { data: products, setData } = useRead<{ [productId: string]: Product }>(
    '/product', loadKey)

  const handleChangeBuilder = (
    pid: string, prop: string) => (
    _: React.MouseEvent | React.ChangeEvent<HTMLInputElement>, value: any) => {
    const ref = `/product/${pid}`
    setData((products) => {
      if (!products) return products
      const updates = { [prop]: value }
      updateRealtime(ref, updates).catch(console.error)
      return { ...products, [pid]: { ...products[pid], ...updates } }
    })
  }
  const productList = Object.entries(products ?? {}).
    filter(([pid, product]) => {
      return tab === 1
        ? !product.deletedAt
        : tab === 3
          ? product.area.toLowerCase() === 'seoul' && !product.deletedAt
          : tab === 4
            ? product.area.toLowerCase() === 'busan' && !product.deletedAt
            : tab === 5
              ? product.area.toLowerCase() === 'tokyo' && !product.deletedAt
              : tab === 6
                ? product.area.toLowerCase() === 'osaka' && !product.deletedAt
                : tab === 7
                  ? product.status === 'ON' && !product.deletedAt
                  : tab === 8
                    ? product.status === 'STOP' && !product.deletedAt
                    : tab === 9
                      ? product.winter
                      : tab === 2
                        ? product.deletedAt
                        : true
    }).
    map(([pid, product]) => ({
      id: pid,
      on: product.status === 'ON',
      winter: !!product.winter,
      area: product.area,
      name: product.name,
      category: product.category,
      availableDays: product.availableDays,
      memo: product.memo,
      option: product.option?.filter(
        o => o.option.toLowerCase() !== 'ignore').length,
      destinations: product.chat?.name?.ko ?? '',
      emailChat: [product.chat?.name?.en, product.chat?.name?.cn].filter(
        a => !!a).join('/'),
      pickup: Object.values(product.chat?.pickup ?? {}).
        filter(p => (p.use === true)).
        sort((a, b) => (a.order ?? Number.POSITIVE_INFINITY) >
        (b.order ?? Number.POSITIVE_INFINITY) ? 1 : -1).
        map(p => [p.ko, p.en, p.cn].filter(a => !!a).join(':')).
        join('/'),
      time: Object.values(product.chat?.pickup ?? {}).
        filter(p => (p.use === true && p.time)).
        sort((a, b) => (a.order ?? Number.POSITIVE_INFINITY) >
        (b.order ?? Number.POSITIVE_INFINITY) ? 1 : -1).
        map(p => `${p.time}${p.winterTime ? `(${p.winterTime})` : ''}`).
        join(', '),
    }))

  const productColumns: GridColDef[] = [
    {
      field: 'status',
      type: 'actions',
      headerName: 'On',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.on ? <ToggleOnIcon color={'primary'}
                                              fontSize={'large'}/> :
            <ToggleOffIcon fontSize={'large'}/>}
          onClick={(e) => {
            handleChangeBuilder(params.row.id, 'status')(e,
              !params.row.on ? 'ON' : 'STOP')
          }}
          label={'star'}
        />,
      ],
    },
    {
      field: 'winter',
      type: 'actions',
      headerName: 'Season',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            params.row.winter ?
              <Icon color={params.row.winter ? 'primary' : 'inherit'}>
                ac_unit
              </Icon>
              :
              <Icon>
                wb_sunny
              </Icon>
          }
          onClick={(e) => {
            handleChangeBuilder(params.row.id, 'winter')(e,
              !params.row.winter ? true : false)
          }}
          label={'star'}
        />,
      ],
    },
    {
      field: 'area',
      headerName: 'Area',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <Chip variant={'outlined'} color={
          params.row.area === 'Busan' ? 'secondary'
            : params.row.area === 'Tokyo' ? 'warning'
              : params.row.area === 'Osaka' ? 'info'
                : 'primary'
        }
                     label={params.row.area}/>
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'availableDays',
      headerName: 'Days',
      minWidth: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Stack flexDirection={'row'} gap={2}>
            {params.row.availableDays?.map(
              (v: boolean, idx: number, array: any) => idx < array.length - 1
                ? (
                  <
                    Typography
                    key={idx}
                    color={v ? 'primary' : 'divider'}
                  >
                    {
                      idx === 0
                        ? '월'
                        : idx === 1
                          ? '화'
                          : idx === 2
                            ? '수'
                            : idx === 3
                              ? '목'
                              : idx === 4
                                ? '금'
                                : idx === 5
                                  ? '토'
                                  : '일'

                    }
                  </Typography>
                )
                : null)}
          </Stack>
        )
      },
    },
    {
      field: 'option',
      headerName: 'Option',
      minWidth: 20,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'destinations',
      headerName: 'Destinations',
      minWidth: 400,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'emailChat',
      headerName: 'Email Chat',
      minWidth: 800,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'pickup',
      headerName: 'Pickup',
      minWidth: 400,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'time',
      headerName: 'Time',
      minWidth: 400,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  if (tab === 2 || tab === 0) // deleted
    productColumns.shift()

  const handleClick: GridEventListener<'rowClick'> = (row, event, context) => {
    setProductId((row as unknown as Product).id)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box sx={{
          position: 'fixed',
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, -1em)',
          zIndex: 999,
          backgroundColor: 'white',
          borderRadius: 5,
          boxShadow: 2,
          py: 2,
          px: 3,
        }}>
          <Button
            fullWidth
            variant={'text'}
            size={'large'}
            startIcon={<FiberNewIcon/>}
            onClick={() => setNewProduct(true)}
          >
            새 상품
          </Button>
        </Box>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
            <Tab label={'All'}/>
            <Tab label={'Valid'}/>
            <Tab label={'Deleted'}/>
            <Tab label={'Seoul'}/>
            <Tab label={'Busan'}/>
            <Tab label={'Tokyo'}/>
            <Tab label={'Osaka'}/>
            <Tab label={'On'}/>
            <Tab label={'Off'}/>
            <Tab label={'Winter'}/>
          </Tabs>
        </Stack>
        <DataGridPro
          columns={productColumns}
          rows={productList}
          onRowClick={handleClick}
        />
      </Box>

      {
        productId
          ? <ProductModal id={productId} onClose={(update) => {
            setProductId(null)
            if (update) {
              setLoadKey(Date.now() + '')
            }
          }}/>
          : null
      }
      {
        newProduct
          ? <CreateProductModal
            onClose={(update) => {
              if (update) setLoadKey(Date.now() + '')
              setNewProduct(false)
            }}
          />
          : null
      }
      {
        modal
          ? <Modal open={modal}>
            <div/>
          </Modal>
          : null
      }
    </>
  )
}
